.nf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto 20vw;
  padding: 15% 0vw;
}

.nf-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
}

.nf-sub-heading {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
}

.nf-content {
  font-style: normal;
  text-align: center;
  margin-bottom: 2rem;
}

.back-button {
  width: auto;
  padding: 1% 10%;
  white-space: nowrap;
  margin: auto;
}
