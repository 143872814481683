.faqcontainer {
  margin-top: 12rem;
  margin-bottom: 8rem;
  padding-left: 1rem;
  padding-top: 6rem;
  position: relative;
  align-items: center;
  width: 100%;
}

.tabscontainer {
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 560px) {
  .faqcontainer {
    margin-top: 6rem;
  }
}
