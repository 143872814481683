.contentbox {
  background-color: #fff4ea;
}

.line {
  border-bottom: 2px solid #ffd0a6;
}

.eachQuestion {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.number {
  color: #ff7a01;
  font-size: 56px;
  line-height: 60px;
  padding-right: 10px;
  width: 50px;
}

.question {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  width: 250px;
  padding-right: 5px;
}

.answer {
  width: 500px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

@media screen and (max-width: 820px) {
  .contentbox {
    width: 100%;
  }
  .number {
    font-size: 48px;
  }
  .question {
    padding-top: 10px;
    padding-right: 15px;
  }
  .answer {
    padding-top: 10px;
  }
}

@media screen and (max-width: 780px) {
  .contentbox {
    width: 100%;
  }
  .number {
    font-size: 48px;
  }
  .question {
    padding-top: 10px;
    padding-right: 15px;
  }
  .answer {
    padding-top: 10px;
  }
}

@media screen and (max-width: 415px) {
  .number {
    font-size: 36px;
  }
  .question {
    font-size: 12px;
    padding-left: 15px;
  }
  .answer {
    font-size: 10px;
  }
}

@media screen and (max-width: 361px) {
  .question {
    font-size: 14px;
    padding-left: 12px;
  }
  .number {
    font-size: 38px;
  }
}

@media screen and (min-width: 1500px) {
  .number {
    font-size: 60px;
    width: 80px;
  }
  .question {
    font-size: 28px;
    width: 400px;
  }
  .answer {
    font-size: 22px;
    width: 700px;
  }
}
