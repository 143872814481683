@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap");

.orangeContainer {
  /* width: 1110px;
    height: 410px; */
  border-radius: 16px;
  z-index: -1;
}

.inputBox {
  padding: 14px 16px;
}

.image {
  /* height: 90vh; */
  margin-top: -8vh;
  margin-right: auto;
  margin-left: auto;
}

@media all and (max-width: 1200px) {
  .inputBox:placeholder-shown {
    font-size: 20px;
  }

  .registerHeading {
    margin: 0 auto;
  }

  .formDiv {
    /* margin: -45vh auto; */
  }

  .inputBox {
    margin-right: auto;
    margin-left: auto;
  }
}

@media all and (min-width: 1200px) {
  .registerPara {
    width: 67vh;
  }
}

@media all and (min-width: 1500px) {
  .image {
    height: 90vh;
  }
}

/* @media (width: 1440px) {
    .registerHeading {
        margin-left: -15vw;
    }
} */

@media all and (max-width: 700px) {
  .image {
    height: 60vh;
    margin-top: -12vh;
  }

  .inputBox {
    width: auto;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30%;
  }

  .registerPara {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
  }
}

@media all and (max-width: 400px) {
  .inputBox {
    width: inherit;
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media all and (max-width: 500px) {
  button {
    margin-top: 3rem;
  }
}

@media (width: 375px) {
  .orangeContainer {
    margin-top: 80vh;
  }
}
