.navbar {
  position: fixed;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 7vh;
  background-color: var(--cryptic-orange);
}

.nav__menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 21.3104px;
  line-height: 32px;
  transition: 0.2s ease-in;
  display: flex;
  justify-content: space-between;
}

.logo > * {
  margin: 0px 4px;
}

.cryptic-hunt-logo {
  height: 35px;
}

.nav__links {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: white;
  transition: 0.2s ease;
  margin: 0px 12px;
}

.logo:hover,
.nav__links:hover {
  color: black;
  transition: 0.2s ease-in;
}

.burger__menu {
  display: none;
}

.burger__menu > div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px;
}

.logout {
  position: absolute;
  right: 2%;
  width: auto;
  font-size: 1rem;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .navbar {
    justify-content: space-around;
    height: 7.5vh;
    position: fixed;
  }

  .navbar > * {
    margin: 0px;
  }

  .burger__menu {
    display: block;
    cursor: pointer;
    transition: 0.2s all ease;
  }

  .nav__menu {
    position: absolute;
    top: 65px;
    height: 35vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-500%);
    transition: 0.3s ease-in-out;
    background-color: var(--cryptic-orange);
    z-index: 0;
  }

  .nav__links {
    font-family: "Inter", sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
  }

  .nav__menu.active {
    transform: none;
    border-bottom-left-radius: 12.5px;
    border-bottom-right-radius: 12.5px;
  }

  .logo,
  .nav__links {
    color: white;
    transition: 0.2s ease-in;
  }

  .logo {
    font-size: 1.5rem;
    z-index: 1;
  }
  .logo:hover,
  .nav__links:hover {
    color: black;
    transition: 0.2s ease-in;
  }

  .burger__menu.is-active .burger__line:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
    transition: 0.2s all ease;
  }

  .burger__menu.is-active .burger__line:nth-child(2) {
    transition: 0.2s all ease;
    opacity: 0;
  }

  .burger__menu.is-active .burger__line:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
    transition: 0.2s all ease;
  }

  .burger__menu .burger__line {
    transition: 0.2s all ease;
  }
}
