@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap");

.orangeContainer {
  /* width: 1110px;
    height: 410px; */
  border-radius: 16px;
  z-index: -1;
}

.inputBox {
  padding: 14px 16px;
}

.image {
  /* height: 90vh; */
  margin-right: auto;
  margin-left: auto;
}

@media all and (max-width: 1200px) {
  .inputBox:placeholder-shown {
    font-size: 20px;
  }

  .codexHeading {
    margin: 0 auto;
  }

  .formDiv {
    margin: -45vh auto;
  }

  .inputBox {
    margin-right: auto;
    margin-left: auto;
  }
  .register-btn {
    margin-left: auto;
  }
}

@media all and (min-width: 1200px) {
  .registerPara {
    width: 67vh;
  }
}

@media all and (max-width: 700px) {
  .image {
    height: 40vh;
  }

  .inputBox {
    width: auto;
    margin-right: auto;
    margin-left: auto;
    margin-top: 150%;
  }

  .codexHeading {
    font-size: 42px;
    line-height: 63px;
  }

  .registerPara {
    font-size: 18px;
    line-height: 27px;
  }
}

@media all and (max-width: 400px) {
  .inputBox {
    width: inherit;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 150%;
  }
}

@media (min-width: 200px) and (max-width: 400px) {
  .inputBox {
    width: inherit;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 270%;
  }
}

@media (min-width: 200px) and (max-width: 370px) {
  .inputBox {
    width: inherit;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 270%;
  }
}

@media (min-width: 370px) and (max-width: 450px) {
  .inputBox {
    width: inherit;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 200%;
  }
}

@media all and (max-width: 500px) {
  button {
    margin-top: 3rem;
  }
}

@media all and (min-width: 1500px) {
  .image {
    width: 34.4vw !important;
  }
}

@media (width: 393px) {
  .inputBox {
    margin-top: 180%;
  }
}
