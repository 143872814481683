.top {
  display: flex;
  background-color: #ff7a01;
  top: 100%;
  padding: 30px;
}

.logos {
  display: flex;
  flex-direction: row;
}

.footer1 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
}

.footer2 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  color: #ffffff;
}

.footer3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: "salt" on, "liga" off;
  color: #ffffff;
}

.social {
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-left: auto;
}

.leftSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

/*Media Queries*/

@media screen and (max-width: 780px) {
  .social {
    gap: 6px;
  }
}

@media screen and (max-width: 415px) {
  .social {
    gap: 10px;
  }
  .footer2 {
    gap: 12px;
  }
  .footer1 {
    gap: 12px;
  }
}

@media screen and (max-width: 468px) {
  .top {
    display: flex;
    flex-direction: column;
  }
  .leftSection {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
  .social {
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    margin-right: auto;
    gap: 0.5em;
  }
}
