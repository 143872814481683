.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--cryptic-cream);
  padding-top: 8%;
}

.heading {
  margin: 5vh 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #070707;
  width: 80vw;
}

.heading > p > span {
  color: var(--cryptic-orange);
}

.app__demo__div {
  height: 400px;
  overflow: hidden;
  margin-bottom: 50px;
}

.appDemo {
  height: 600px;
  margin-bottom: 2vh;
}

.details {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 60vw;
  display: grid;
  grid-template-columns: auto;
}

.details span {
  margin-top: 10px;
}

.store__badge {
  margin-bottom: 7.5vh;
  display: flex;
  z-index: 10;
}

.store__badge > * {
  padding: 0px 12.5px;
}

.badge {
  height: 75px;
}

.explore {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  margin-bottom: 4vh;
  text-align: center;
}

.checkin-btn {
  margin: 1% auto 5% auto;
  width: auto;
  padding: 0.5% 4%;
  font-size: 1.2rem;
  background-color: #ff7a01;
  border-radius: 5px;
  color: #fff;
  border: 0px;
}

@media screen and (max-width: 768px) {
  .hero {
    padding-top: 14%;
  }
  .heading {
    font-size: 42px;
    line-height: 63px;
  }
  .details {
    font-size: 18px;
    line-height: 27px;
    width: 80vw;
  }
  .explore {
    font-size: 42px;
    line-height: 55px;
    /* margin: 5px 25px; */
  }

  .app__demo__div {
    height: 300px;
  }

  .appDemo {
    height: 500px;
  }

  .badge {
    height: 50px;
  }
  .store__badge > * {
    padding: 0px 7.5px;
  }
}
